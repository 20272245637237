import React from 'react';
import Layout from 'components/layout';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

function NotFoundPage() {
  return (
    <Layout headerOpacity={1}>
      <Box pt={15}>
        <Typography component="p" variant="h2" align="center">
          Sorry, that page doesnt exist!
        </Typography>
      </Box>
    </Layout>
  );
}

export default NotFoundPage;
